#nav-container {
	// display: grid;
	margin-top: 1em;
	margin-left: 0.5vw;
	margin-right: 0.5vw;
	display: flex;
	justify-content: center;
	align-items: center;
}

#nav-container nav {
	text-align: center;
}

nav a {
	text-align: center;
	margin: 0em 0.2em;

}

nav a.current {
	color: $swaggy-purple;
	font-weight: bolder;
}

nav a:first-child {
	display: inline-block;
	padding-bottom: .2em;
}

.clear-space{
	clear: both;
	height: 75px;
}

#footer-container {
	height: 25px;
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: $foamy-green;
	text-align: center;
}
