@keyframes fade-in {
	0% {
		transform: translateY(0);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

// #nav-container {
// 	animation: nav-animation 3s;
// }

// .fade-in {
// 	animation: fade-in 3s;
// }

.fade-in-fast {
	animation: fade-in 250ms;
}