div#john-peeking {
	position: fixed;
	right: 0;
	bottom: 15%;
}

@keyframes popout {
	0% {
		transform: translateX(220px);
	}
	20%, 70% {
		transform: translateX(0px) scaleY(1);	
	}
	75% {
		transform: scaleY(-1);
	}
	100% {
		transform: translateY(-1000px) scaleY(-1);
	}
}

div#john-peeking img {
	display: block;
	animation: popout 5s forwards;
	transform: translateX(240px);
	animation-delay: 5s;
}