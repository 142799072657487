/* colors */

$swaggy-purple: #A822D0;
$light-swaggy-purple: #e0a7f1;
$super-light-pink: #fbf0fc;
$super-light-canary: #fcfcf7;
$bright-green: #bbff99;
$dark-grey: #282828;
$foamy-green: #f6fcf6;
$whatever-dawg-im-just-a-placeholder: #A822D0;

/* fonts */
$default-font: 'Roboto', sans-serif;
$title-font: 'Roboto Mono', sans-serif;