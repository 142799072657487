div.body-content-box {
	width: 80%;
	margin: auto;
	text-align: center;
}

div.main-content-div {
	// @extend .fade-in-fast;
	width: 100%;
	max-width: 400px;
	min-width: 250px;
	margin: auto;
	text-align: left;
}

div.announcement-content-div {
	width: 60%;
	margin: auto;
	text-align: center;
}

.announcement-box {
	padding-top: 1em;
	padding-bottom: 1em;
	margin-top: 20px;
	margin-bottom: 2em;
	border-style: solid;
	border-width: 2px;
}

// div.title-content-div {
// 	width: 60%;
// 	margin: auto;
// 	text-align: center;
// 	display: block;
// 	font-size: calc(7px + 0.7vw);
// }

.title-box {
	font-weight: normal;
	padding-top: 0.3em;
	padding-bottom: 0.3em;
	margin-top: 20px;
	margin-bottom: 1em;
	border-style: solid;
	border-width: 2px;
}

.green-box {
	border-color: black;
	background-color: #bbff99;
	color: black;
}

.purple-box {
	border-color: black;
	background-color: $swaggy-purple;
	color: white;
}

.pink-box {
	border-color: black;
	background-color: hotpink;
	color: white;
}

// .dark-grey-box {
// 	border-color: black;
// 	background-color: $dark-grey;
// 	color: white;
// }