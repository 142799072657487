body {
	font-family: $default-font;
	margin: 0;
	background-color: $super-light-canary;
}

p {
	margin: 1.35em 0;
	line-height: 1.25;
}

ul {
	list-style-type: none;
	list-style-position: outside;
}

li {
	margin: 1.35em 0;
	line-height: 1.25;
}

a {
	color:black;
	transition: color 250ms ease-in-out;
}

a:hover {
	color: $swaggy-purple;
}

a.current {
	color: $swaggy-purple;
	font-weight: bolder;
}

a.blog-title {
	color: $swaggy-purple;
	font-weight: bolder;
}

p.blog-title {
	color: $swaggy-purple;
	font-weight: bolder;
}

.PageNavigation {
  font-size: 14px;
  display: block;
  width: auto;
  overflow: hidden;
}

.PageNavigation a {
  display: block;
  width: 50%;
  float: left;
  margin: 1em 0;
}

.PageNavigation .next {
  text-align: right;
}

.float-right {
	float: right;
}

.float-left {
	float: left;
}

.bluelink {
	color: dodgerblue;
	// color: white;
}

// @keyframes flyaway {
// 	0% {
// 		transform: translateY(0px);
// 	}
// 	100% {
// 		transform: translateY(-300px);	
// 	}
// }



// div#john-peeking a {
// 	&:visited {
// 		img {
// 			animation: flyaway 1s;
// 		}
// 	}
// }

.no-list-style {
	list-style: none;
	padding: 0;
	line-height: 1.25;
}

ol { 
   list-style-type: decimal; 
   list-style-position: inside; 
}

/* visualize divs */
// div { outline: 1px solid red !important; }

.rounded-corners {
	border-radius: 5px;
}